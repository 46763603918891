<template>
  <div id="app">
    <div>
    <van-nav-bar
  title="星座运势详情"
  left-arrow
  fixed="true"
 @click-left="$router.back(-1)"
/>
    </div>

    <!--星座-->
 <div class="content">
       <!--图片-->
   <div  class="main1">
     <van-row>
     <van-col span="8"><img
                    class="icon4"
                    referrerpolicy="no-referrer"
                    :src="flexcontent[index].img"
                  /></van-col>
     <van-col span="16"> <span style="display:block;margin-left:10px"  class="info-1">{{name}}</span>
     <span style="color: rgba(255, 255, 255, 1);
     font-size: 14px;margin-left:10px">今日运势{{today.datetime}}</span></van-col>
    </van-row>
    
   </div>
  <!--图片-->
 <div class="main">

   <div class="box">
     
       <div class="left">
         <div class="item"> <div class="key">综合指数</div> <div class="value">{{today.all}}</div> </div>
          <div class="item"> <div class="key">爱情指数</div> <div class="value">{{today.love}}</div> </div>
           <div class="item"> <div class="key">工作指数</div> <div class="value">{{today.work}}</div> </div>
            <div class="item"> <div class="key">速配星座</div> <div class="value">{{today.QFriend}}</div> </div>
        
        
      
     </div>
      <div class="right">
         <div class="item"> <div class="key">健康指数</div> <div class="value">{{today.health}}</div> </div>
          <div class="item"> <div class="key">爱情指数</div> <div class="value">{{today.money}}</div> </div>
           <div class="item"> <div class="key">工作指数</div> <div class="value">{{today.number}}</div> </div>
            <div class="item"> <div class="key">速配星座</div> <div class="value">{{today.color}}</div> </div>
        
        
      
     </div>
   </div>
  
  
</div> 
  <!--图片-->
<div class="main2">
  <!--今日概述-->
   <div class="div-title"> <img
              class="label2"
              referrerpolicy="no-referrer"
              src="/img/star/Star.png"
            /><span  class="info5">今日概述</span></div>
   <div><span class="text"> {{today.summary}} </span></div>
<!--本周运势-->
           <div class="div-title"> <img
              class="label2"
              referrerpolicy="no-referrer"
              src="/img/star/Star.png"
            /><span  class="info5">本周运势</span><span class="word13">{{week.data}}</span></div>
      <div><span  class="text">  健康运势：{{week.health}}
     
           <div class="text">职场运势：{{week.job}}</div> 
      <div class="text">恋情：{{week.love}}</div>
            
          <div class="text">财运：{{week.money}}</div>
            
        <div class="text">工作：{{week.work}}</div>
            
          </span></div>
<!--本年运势-->
              <div class="div-title"><img
              class="label2"
              referrerpolicy="no-referrer"
              src="/img/star/Star.png"
            /><span  class="info5">本年运势</span><span class="word13" >{{year.date}}</span></div>
   <div><span  class="text" >年度密码：{{year.mima.info}}
           
           {{year.mima.text}}  <br />
           <span  class="text">事业运：{{year.career}}</span> 
            <br />
            <span  class="text">感情运：{{year.love}}</span> 
            <br />
           
            <span class="text"> 财运：{{year.finance}}</span>
          </span></div>
<!--本月运势-->
              <div class="div-title"> <img
              class="label2"
              referrerpolicy="no-referrer"
              src="/img/star/Star.png"
            /><span  class="info5" >本月运势</span><span class="word13">{{month.date}}</span></div>
   <div><span class="text">{{month.all}}
          <div> {{month.health}}</div>
          <div>{{month.love}}</div>
          <div>{{month.money}}</div>
          <div>{{month.work}}</div>
            
          </span></div>



          <div style="hegith:50px">
          </div>

 </div> 

  </div>
  </div>
</template>
<script>
  import {getConstellationObj} from '@/api/weatherforecast/weatherforecast'
export default {
   data () {
    return {
      name:'',
      today:'',
      tomorrow:'',
      week:'',
      month:'',
      year:'',
      index:0,
      flexcontent: [
        {
          name: "白羊座",
          img:
            "img/star/baiyang.png",
          content:'3.21-4.19'
        },
       {
          name: "金牛座",
          img:
            "img/star/jinniu.png",
          content:'4.20-5.20'
        },
        {
          name: "双子座",
          img:
            "img/star/shuangzi.png",
          content:'5.21-6.21'
        },
        {
          name: "巨蟹座",
          img:
            "img/star/juxie.png",
          content:'6.22-7.22'
        },
        {
          name: "狮子座",
          img:
            "img/star/shizi.png",
          content:'7.23-8.22'
        },
        {
          name: "处女座",
          img:
            "img/star/chunv.png",
          content:'8.23-9.22'
        },
       {
          name: "天秤座",
          img:
            "img/star/tianping.png",
          content:'9.23-10.23'
        },
        {
          name: "天蝎座",
          img:
            "img/star/tianxie.png",
          content:'10.24-11.22'
        },
        {
          name: "射手座",
          img:
            "img/star/sheshou.png",
          content:'11.23-12.21'
        },
        {
          name: "摩羯座",
          img:
            "img/star/mojie.png",
          content:'12.22-1.19'
        },
        {
          name: "水瓶座",
          img:
            "img/star/shuiping.png",
          content:'1.20-2.18'
        },
         {
          name: "双鱼座",
          img:
            "img/star/shuangyu.png",
          content:'2.19-3.20'
        },
        ],
    }
   },
  created(){
    this.name=this.$route.query.name
    this.index=this.$route.query.index
    this.getTodayData(this.$route.query.name)
    this.getTomorrowData(this.$route.query.name)
    this.getWeekData(this.$route.query.name)
    this.getMonthData(this.$route.query.name)
    this.getYearData(this.$route.query.name)
   //this.tomorrow=this.getData(this.$route.query.name,'tomorrow')
   //this.week=this.getData(this.$route.query.name,'week')
   //this.month=this.getData(this.$route.query.name,'month')
   //this.year=this.getData(this.$route.query.name,'year')
},
  mounted () {
    
  },
 methods: {
    getTodayData(name)
    {
     let params ={consName:name,type:'today'}
     getConstellationObj(params).then((data) => {
          this.today=data.data.data
                }).catch(err=>{
                        console.log(err)   
                    }
                )
    },
    getTomorrowData(name)
    {
     let params ={consName:name,type:'tomorrow'}
     getConstellationObj(params).then((data) => {
          this.tomorrow=data.data.data
                }).catch(err=>{
                        console.log(err)   
                    }
                )
    },
    getWeekData(name)
    {
     let params ={consName:name,type:'week'}
     getConstellationObj(params).then((data) => {
          this.week=data.data.data
                }).catch(err=>{
                        console.log(err)   
                    }
                )
    },
    getMonthData(name)
    {
     let params ={consName:name,type:'month'}
     getConstellationObj(params).then((data) => {
          this.month=data.data.data
                }).catch(err=>{
                        console.log(err)   
                    }
                )
    },
    getYearData(name)
    {
     let params ={consName:name,type:'year'}
     getConstellationObj(params).then((data) => {
          this.year=data.data.data
                }).catch(err=>{
                        console.log(err)   
                    }
                )
    },
   },
   
}
</script>
<style lang="less" scoped>
.content
{
  background-color: #8471EE;
  width:100%;
  height: 100%;
}
.main1{
  margin-left: 5px;
  margin-right: 5px;
  background-color: #8471EE;
  padding-top: 60px;
  }
  .main2{
  margin-top:-220px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: white;
   border-radius: 5px;
   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
   padding-bottom:10em;
   padding:  15px;
  }
.main-content{
  background-color: #FF333333
  }
.main{
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  overflow:hidden;
  border-radius: 5px;
 background-color: rgba(51, 51, 51, 0.3);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-content: center;
  height: 400px;
  }
.left{
    width:25%;
     height:90px;
     float:left;}
.right{ width:25%;
    height:90px;
    float:right; }
.middle{ 
     width:50%;
     height:90px;
     float:left; }
.word2 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 61px;
  font-family: PingFangSC-Regular;
  line-height: 86px;
  text-align: left;
}
.word3 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  font-family: PingFangSC-Regular;
  line-height: 45px;
  text-align: left;
}
.info2 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin-left:10px
}
.txt {
  overflow-wrap: break-word;
  color: rgba(255, 214, 130, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 13px;
}
.txt1{
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 15px;
}
.info5 {
  overflow-wrap: break-word;
  color: rgba(85, 144, 227, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 5px;
}
.word13 {
  overflow-wrap: break-word;
  color: rgba(85, 144, 227, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;
  text-align: left;
  margin-top: 7px;
  margin-left: 5px;
}
.info-1 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  align-self: flex-start;
}
.div-title
{
  display: flex;
  align-items: center;
   margin-bottom: 10px;
   
}
.text{ 
  display: block;
  text-indent: 28px;
  font-size: 12px;
 
}
.box{ 

  display: flex;
  align-items: center;
  padding: 15px 22px;
 .left,.right{ 
   width: 50%;
   font-family: PingFangSC-Medium, PingFang SC;
   .item{ 
     display: flex;
     align-items: center;
     font-size: 18px;
     margin-bottom: 10px;
     .key{ 
       color: white;
       margin-right: 13px;
     }
     .value{ 
       color: #FFD682;
     }
   }
   
 }
}
</style>
